body {
  background-color: #f0f0f0;
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 700;
}

.nav-link {
  color: #000;
}

.nav-link:hover {
  color: #dad7cd;
}

.navbar-logo {
  display: inline-block;
  width: auto; /* Adjust based on your logo's aspect ratio */
  height: auto; /* Adjust based on your logo's aspect ratio */
  max-height: 150px;
  max-width: 150px;
  /* background-size: contain;  */
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
}

.hero-section {
  background-image: url("./assets/hero.png");
  background-size: cover;
  background-position: center top;
  height: 100vh;
  transition: background-position 0.3s ease-out;
}

.hero-section h1 {
  color: lightgoldenrodyellow;
  font-size: 60px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
}

.hero-section p {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  font-weight: 500;
  color: rgb(255, 255, 131);
}
.about-section {
  padding-top: 5rem;
  padding-bottom: 8rem;
  animation: slideInFromLeft 1s ease-out;
}

.about-section h2 {
  font-size: 40px;
}

.featured-vid {
  background-color: #ffffff;
  padding: 2rem;
}

.featured-vid h1 {
  font-size: 40px;
}

.video-element.show {
  display: block;
}

.video-overlay.hide {
  display: none;
}

.hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.show {
  opacity: 1;
  transform: translateY(0);
}

.about-container {
  background-color: antiquewhite;
  padding: 1rem;
}

.content-inner-container {
  background-color: antiquewhite;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.business-video-content {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem;
}

.brochure-box-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background-color: antiquewhite;
  flex-direction: column;
}

.outer-brochure-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.brochure-box {
  margin: 15px; /* Add some space around each brochure */
  text-align: center; /* Center the title and thumbnail */
  transition: transform 0.3s ease; /* Smooth effect on hover */
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.brochure-thumbnail {
  width: 100%;
  max-width: 250px;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensures thumbnails fit well without distortion */
  border-radius: 8px; /* Add some rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  margin: 1rem;
}

.footer-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-col span {
  color: antiquewhite;
}

.footer-rights {
  font-size: 0.7rem;
}

@media (max-width: 768px) {
  .brochure-box-container {
    flex-direction: column;
    align-items: center;
  }

  .business-video-content {
    flex-direction: column;
  }

  .brochure-box {
    flex-direction: column;
  }

  .hero-section h1 {
    font-size: 50px;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
